import React from "react";
import * as styles from "./contact-info.module.scss";

function ContactInfo({ address, tel, email, facebook, children }) {
  return (
    <div className={`${styles.contactInfo} card bg-dark`}>
      <ul className={styles.list}>
        <li>
          <svg
            className={styles.icon}
            height="20"
            width="20"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
            <circle cx="12" cy="10" r="3" />
          </svg>
          <address>{address}</address>
        </li>
        <li>
          <svg
            className={styles.icon}
            height="20"
            width="20"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
          </svg>
          <span>{tel}</span>
        </li>
        <li>
          <svg
            className={styles.icon}
            height="20"
            width="20"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
            <polyline points="22,6 12,13 2,6" />
          </svg>
          <a href={`mailto:${email}`}>{email}</a>
        </li>
        <li>
          <svg
            className={styles.icon}
            height="20"
            width="20"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
          </svg>
          <a href={facebook} target="_blank" rel="noreferrer">
            Facebook
          </a>
        </li>
      </ul>

      <div>{children}</div>
    </div>
  );
}

export default ContactInfo;
