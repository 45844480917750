import React from "react";
import * as styles from "./map.module.scss";

function Map({ address }) {
  const apiKey = "AIzaSyDxOkx6tHdzYNoUupj-qme2uzI0CU7vwFc";
  const mapSrc = `https://www.google.com/maps/embed/v1/place?q=${encodeURI(address)}&key=${apiKey}`;

  return (
    <iframe
      className={styles.map}
      frameBorder="0"
      title="Google Maps"
      src={mapSrc}
      allowFullScreen
      referrerPolicy="strict-origin"
    ></iframe>
  );
}

export default Map;
