import Alert from "components/alert/alert";
import Button from "components/button/button";
import NeedsJs from "components/needs-js/needs-js";
import React, { useState } from "react";
import * as styles from "./contact-form.module.scss";

function ContactForm() {
  const [form, setForm] = useState({ name: "", email: "", message: "" });
  const [validations, setValidations] = useState({ name: "", email: "", message: "" });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  function handleChange(event) {
    event.preventDefault();
    setSubmitError(false);
    setSubmitSuccess(false);
    const { name, value } = event.target;
    form[name] = value;
    setForm({ ...form });
    if (submitted) validate();
  }

  function validate() {
    validations.name = form.name.length == 0 ? "Ongeldige naam" : "";
    validations.email = form.email.length == 0  ? "Ongeldig e-mailadres" : "";
    validations.message = form.message.length == 0 ? "Gelieve een boodschap in te vullen" : "";
    setValidations({ ...validations });
  }

  function isValid() {
    return validations.name === "" && validations.email === "" && validations.message === "";
  }

  async function onSubmit(e) {
    if (loading) return;
    e.preventDefault();
    setSubmitted(true);
    validate();
    if (isValid()) {
      setLoading(true);
      await sendEmail();
      setLoading(false);
    }
  }

  async function sendEmail() {
    var endpoint = "/.netlify/functions/send-email";
    var headers = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(form),
    };

    const response = await fetch(endpoint, headers)
      .then(async (response) => {
        if (response.status !== 200) {
          setSubmitError(true);
          return await response.json();
        }
        reset();
        setSubmitSuccess(true);
        return await response.json();
      })
      .catch((error) => {
        setSubmitError(true);
        return error;
      });

    console.log(response);
  }

  function reset() {
    setForm((form) => {
      form.name = "";
      form.email = "";
      form.message = "";
      return form;
    });
    setSubmitted(false);
  }

  return (
    <div className={`${styles.contactForm} card`}>
      <NeedsJs>
        <form onSubmit={onSubmit} method="POST" action="#">
          <div className={`${styles.formGroup} ${validations.name.length > 0 ? styles.error : ""}`}>
            <label htmlFor="name">Naam:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={form.name || ""}
              onChange={handleChange}
              disabled={loading}
            />
            <div className={styles.validationMessage}>
              {validations.name.length > 0 && `* ${validations.name}`}
            </div>
          </div>

          <div
            className={`${styles.formGroup} ${validations.email.length > 0 ? styles.error : ""}`}
          >
            <label htmlFor="email">E-mail:</label>
            <input
              type="mail"
              id="email"
              name="email"
              value={form.email || ""}
              onChange={handleChange}
              disabled={loading}
            />
            <div className={styles.validationMessage}>
              {validations.email.length > 0 && `* ${validations.email}`}
            </div>
          </div>

          <div
            className={`${styles.formGroup} ${validations.message.length > 0 ? styles.error : ""}`}
          >
            <label htmlFor="message">Bericht:</label>
            <textarea
              id="message"
              name="message"
              rows="8"
              value={form.message || ""}
              onChange={handleChange}
              disabled={loading}
            />
            <div className={styles.validationMessage}>
              {validations.message.length > 0 && `* ${validations.message}`}
            </div>
          </div>

          {submitError && (
            <div className={styles.formGroup}>
              <Alert type="error">
                Het bericht kan niet worden verzonden, onze excuses. Indien het probleem zich blijft
                voordoen, kan je jouw bericht ook rechtstreeks versturen naar{" "}
                <a href="mailto:info@sonja-vervoort.be">info@sonja-vervoort.be</a>.
              </Alert>
            </div>
          )}

          {submitSuccess && (
            <div className={styles.formGroup}>
              <Alert type="success">Jouw bericht is verzonden, bedankt.</Alert>
            </div>
          )}

          {!submitSuccess && !submitError && (
            <Button type="submit" size="lg" loading={loading}>
              Bericht verzenden
            </Button>
          )}
        </form>
      </NeedsJs>
    </div>
  );
}

export default ContactForm;
