import ContactForm from "components/contact-form/contact-form";
import ContactInfo from "components/contact-info/contact-info";
import Map from "components/map/map";
import Newsletter from "components/newsletter/newsletter";
import Quote from "components/slices/quote/quote";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import React from "react";
import { repositoryConfig } from "utils/repositoryConfig";
import Container from "../components/container/container";
import PageTitle from "../components/page-title/page-title";
import SEO from "../components/seo";
import * as styles from "./contact.module.scss";

function ContactPage({ data }) {
  const pageData = data.prismicContactPage.data;
  const { title_bg, title } = pageData;
  const address = pageData.address;
  const tel = pageData.tel;
  const email = pageData.email;
  const facebook = pageData.facebook_link;
  const info = pageData.info;
  const quote = pageData.bottom_quote;

  return (
    <>
      <SEO title="Contact" />
      <PageTitle bg={title_bg}>{title.text}</PageTitle>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.contact}>
            <div className={styles.contactInfo}>
              <ContactInfo address={address} tel={tel} email={email} facebook={facebook}>
                {info}
              </ContactInfo>
            </div>
            <div className={styles.contactForm}>
              <ContactForm></ContactForm>
            </div>
          </div>
          <div className={styles.map}>
            <Map address={address}></Map>
          </div>
        </div>
      </Container>
      <Newsletter></Newsletter>
      <Quote html={quote}></Quote>
    </>
  );
}

export const Query = graphql`
  {
    prismicContactPage {
      _previewable
      data {
        title {
          text
        }
        title_bg {
          thumbnails {
            xs {
              url
            }
            sm {
              url
            }
            md {
              url
            }
            lg {
              url
            }
            xl {
              url
            }
            max {
              url
            }
          }
        }
        address
        tel
        email
        facebook_link
        info
        bottom_quote
      }
    }
  }
`;

export default withPrismicPreview(ContactPage, repositoryConfig);
